<template>
  <v-container>
    <pricing-table v-bind="props" :update-route="true" :forced-filters="{client: '0'}"></pricing-table>
  </v-container>
</template>
<script>

import PricingTable from '@/components/PricingTable'
import {pricingTable} from '@/tables'

export default {
  components: {
    PricingTable
  },
  data () {
    return {
      props: pricingTable
    }
  }
}
</script>